<template>
  <v-row>
    <v-col md="4" offset-md="8">
      <a
        style="text-decoration: underline"
        @click="
          $set(paymentPreviewDialog, value.order_id, true);
          $refs[value.order_id].getCustomerPayments();
        "
      >
        <span class="ml-10">Less Amount Paid </span>
        <span class="float-right">{{
          formatAsCurrency("R", amount_paid)
        }}</span>
      </a>
      <InvoicePaymentPreviewModal
        :ref="value.order_id"
        :paymentPreviewDialog="paymentPreviewDialog"
        :value="value"
        :type="'order'"
      />
    </v-col>
  </v-row>
</template>
<script>
import { formatAsCurrency } from "../../../composables/external";

export default {
  name: "ViewSalesOrdersPaymentSummary",
  props: ["customer_payments", "amount_paid", "value"],
  components: {
    InvoicePaymentPreviewModal: () =>
      import(
        "../../Accounting/components/Modals/InvoicePaymentPreviewModal.vue"
      ),
  },
  data() {
    return {
      dialog: false,
      paymentPreviewDialog: {},
    };
  },
  methods: {
    formatAsCurrency,
    //
    getCPAPortions() {
      //  Get allocated portion array prop
      //  Loop through array and ignore first item
      //  Map rest to template
    },
  },
};
</script>
